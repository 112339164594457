import React from 'react';
import {motion} from "framer-motion"


function Contact() {
  const button_clicked = (e) => {
    e.preventDefault()
    console.log("Send contact info to server...")
  }

  return (
    <motion.div className='content-container contact-container' initial={{opacity: 0}} animate={{opacity:1}}>
      <motion.h1 initial={{scale: 0.8}} animate={{scale: 1}} className="title">Dolgozzunk együtt!</motion.h1>
      <h3>Ha egyedi illusztrációra van szükséged, karakter tervezőt vagy animátort keresel, árajánlatért írj nekem bátran az e-mail címemre:</h3>
      <h3 className='selectable-text'>cheellart@gmail.com</h3>
    </motion.div>
  )
}

export default Contact