import React, { useContext, useState } from 'react'
import { ImageViewerContext } from '../contexts/ImageViewerContext'
import {motion, AnimatePresence} from "framer-motion"

const variants = {
    enter: (direction) => {
      return {
        x: direction > 0 ? 1000 : -1000,
        opacity: 0
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1
    },
    exit: (direction) => {
      return {
        zIndex: 0,
        x: direction < 0 ? 1000 : -1000,
        opacity: 0
      };
    }
  };

export default function ImageViewer() {
    const {image_data, set_image_data, set_previous_image, set_next_image, image_index, image_list} = useContext(ImageViewerContext)
    const [[page, direction], setPage] = useState([0, 0]);

    const swipeConfidenceThreshold = 10000;
    const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
    };

    if(image_data === null) return

    document.body.style.overflowY = "hidden";

    const paginate = (newDirection) => {
        setPage([page + newDirection, newDirection]);
      };

    const close_clicked = () =>{
        set_image_data(null)
        document.body.style.overflowY = "auto";
    }

    const next_image_left = (e) => {
        e.stopPropagation()
        paginate(-1)
        set_previous_image()
    }

    const next_image_right = (e) => {
        e.stopPropagation()
        paginate(1)
        set_next_image()
    }

    return (
        <div className='image-viewer-container' onClick={close_clicked}>
            <div className='details-container'>
                {
                    image_index === 0? null: <motion.i className='fa fa-chevron-left left-button' onClick={next_image_left} whileHover={{scale: 1.2}} initial={{x:-50}} animate={{x:0}}/>
                }
                {
                    image_index === image_list.length -1? null: <motion.i className='fa fa-chevron-right right-button' onClick={next_image_right} whileHover={{scale: 1.2}} initial={{x:50}} animate={{x:0}}/>
                }               
                
                <motion.div className='image-container' initial={{scale: 0.8}} animate={{scale:1}}>
                    <AnimatePresence initial={false} custom={direction}>
                      {
                        image_data.type === "image"?
                        <motion.img 
                            key={image_data.id}
                            src={image_data.media}
                            custom={direction}
                            variants={variants}
                            initial="enter"
                            animate="center"
                            exit="exit"
                            transition={{
                                x: { type: "spring", stiffness: 300, damping: 30 },
                                opacity: { duration: 0.2 }
                            }}
                            drag="x"
                            dragConstraints={{ left: 0, right: 0 }}
                            dragElastic={1}
                            onDragEnd={(e, { offset, velocity }) => {
                              const swipe = swipePower(offset.x, velocity.x);
                  
                              if (swipe < -swipeConfidenceThreshold) {
                                next_image_right(e);
                              } else if (swipe > swipeConfidenceThreshold) {
                                next_image_left(e);
                              }
                            }}

                            onClick={e => e.stopPropagation()}
                        />
                        :
                        <motion.video 
                          autoPlay loop 
                          muted 
                          playsInline

                          key={image_data.id}
                          custom={direction}
                          variants={variants}
                          initial="enter"
                          animate="center"
                          exit="exit"
                          transition={{
                            x: { type: "spring", stiffness: 300, damping: 30 },
                            opacity: { duration: 0.2 }
                          }}
                          drag="x"
                          dragConstraints={{ left: 0, right: 0 }}
                          dragElastic={1}
                          onDragEnd={(e, { offset, velocity }) => {
                            const swipe = swipePower(offset.x, velocity.x);
                
                            if (swipe < -swipeConfidenceThreshold) {
                              next_image_right(e);
                            } else if (swipe > swipeConfidenceThreshold) {
                              next_image_left(e);
                            }
                          }}

                          onClick={e => e.stopPropagation()}
                        >
                          <source src={image_data.media} type="video/mp4"/>
                          Your browser does not support the video tag.
                        </motion.video>
                      }

                    </AnimatePresence>
                </motion.div>                

                <motion.div initial={{y: 50}} animate={{y:0}} className="text-container">
                    <h2>{image_data.title}</h2>
                    <div dangerouslySetInnerHTML={{ __html: image_data.description}} onClick={e => e.stopPropagation()}/>
                </motion.div>
            </div>
        </div>
    )
}
