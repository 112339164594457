import React from "react";
import Profilkep from "../images/cheellart-profile-pic.png"
import Fejlec from "../images/cheellart-title.gif"
import SocialLinks from './SocialLinks';
import { motion } from "framer-motion"


function Header() {
    return(
        <div className='header content-container'>
            <div className="profile-container">
                <motion.img src={Profilkep} 
                    alt="" 
                    className='profile-picture'
                    initial={{ opacity: 0, x: -100 }}
                    animate={{ opacity: 1, x: 0 }}
                    draggable="false"
                />
                
                <motion.div 
                    className='title-container'
                    initial={{ opacity: 0, y: -100 }}
                    animate={{ opacity: 1, y: 0 }}
                >
                    <img src={Fejlec} alt="" draggable="false"/>
                </motion.div>
            </div>

            <SocialLinks/>
        </div>
    )
}

export default Header