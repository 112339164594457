import React, {createContext, useState} from "react";
import { useEffect } from "react";
import axios from "axios";
import { useLocation } from 'react-router-dom'
 
export const AnimationViewerContext = createContext(true);
 
const API_URL = process.env.REACT_APP_API_URL

export const AnimationViewerProvider = (props) => {
    const [anim_data, set_anim_data] = useState(null);
    const [details_url, set_details_url] = useState(null)
    const location = useLocation()

    const get_api_url = () => {
        const route_list = {
            "/illusztracio": "illustrations",
            "/animacio": "animations",
            "/karakter-design": "character-designs",
            "/egyeb-grafikak": "other-graphics",
        }
        return route_list[location.pathname]
    }

    const fetch_anim_data = () => {
        axios({
            method: "get",
            url: `${API_URL}/api/${details_url}/`
        }).then(res => set_anim_data(res.data))
    }

    useEffect(()=> {
        const queryParams = new URLSearchParams(location.search)
        const image_id = queryParams.get("imageid")
        if(image_id){
            set_details_url(`${get_api_url()}/${image_id}`)
        }
    }, [])

    useEffect(() => {
        if(details_url){
            fetch_anim_data()
        }else{
            set_anim_data(null)
        }
    }, [details_url])
 
    return (
        <AnimationViewerContext.Provider
            value={{
                anim_data:anim_data,
                set_details_url:set_details_url,
                set_anim_data:set_anim_data
            }}>
 
            {props.children}
 
        </AnimationViewerContext.Provider>
    )
};