import React, { Fragment, useState, useEffect} from 'react';
import {Link} from "react-router-dom"
import { motion, useCycle} from "framer-motion"
import { useLocation } from 'react-router-dom'

function MenuItems() {
  const location = useLocation()

  return <Fragment>
      <div className={location.pathname === "/"? "active-page":""}><Link to="/">Kezdőlap</Link></div>
      <div className={location.pathname === "/illusztracio"? "active-page":""}><Link to="/illusztracio">Illusztráció és Concept Art</Link></div>
      <div className={location.pathname === "/animacio"? "active-page":""}><Link to="/animacio">Animáció</Link></div>
      <div className={location.pathname === "/karakter-design"? "active-page":""}><Link to="/karakter-design">Karakter Dizájn</Link></div>
      <div className={location.pathname === "/egyeb-grafikak"? "active-page":""}><Link to="/egyeb-grafikak">Egyéb Grafikák</Link></div>
      <div className={location.pathname === "/szolgaltatasok"? "active-page":""}><Link to="/szolgaltatasok">Szolgáltatások</Link></div>
      <div className={location.pathname === "/rolam"? "active-page":""}><Link to="/rolam">Rólam</Link></div>
      <div className={location.pathname === "/kapcsolat"? "active-page":""}><Link to="/kapcsolat">Kapcsolat</Link></div>
  </Fragment>
}

function DesktopMenu(){
  return (
    <div className='navbar content-container'>
      <motion.div 
      className="menu-container"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      >
        <MenuItems/>
      </motion.div>
    </div>
  )
}

function MobileMenu() {
  const [open, set_opened] = useCycle(false, true);

  const menuVariants = {
    closed: {
      y: -300
    },
    open: {
      y:0
    }
  };

  return <div className='mobile-navbar'>
    <i className="fa fa-bars mobile-nav-button" onClick={set_opened}/>
    {
      open&& <div className='close-menu-box' onClick={set_opened}/>
    }
    
    {
      open &&
      <motion.div 
        className='menu-items' 
        variants={menuVariants} 
        transition={{type: "tween"}} 
        initial="closed" 
        animate="open" 
        onClick={set_opened}
      >
        <MenuItems/>
      </motion.div>   
    }
  </div>
}

function Navbar() {
  const [mobile, set_mobile] = useState(window.innerWidth < 500)

  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 500) {
      set_mobile(true)
    } else {
      set_mobile(false)
    }
  }

  // create an event listener for resizing window
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  return (
      <Fragment>
        {
          mobile? <MobileMenu/> : <DesktopMenu/ >
        }      
      </Fragment>
  );
}

export default Navbar;