import React from 'react';
import Footer from './components/Footer';
import Home from './components/Home';
import Navbar from './components/Navbar';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import About from './components/About';
import Contact from './components/Contact';
import Illusztracio from './components/Illusztracio';
import Animacio from './components/Animacio';
import KarakterDesign from './components/KarakterDesign';
import Header from './components/Header';
import ImageViewer from './components/ImageViewer';
import { ImageViewerProvider } from './contexts/ImageViewerContext';
import { AnimationViewerProvider } from './contexts/AnimationViewerContext';
import ScrollToTop from './components/micro/ScrollToTop';
import AnimDetailsView from './components/AnimDetailsView';
import OtherGraphics from './components/OtherGraphics';
import Szolgaltatasok from './components/Szolgaltatasok';


function App() {
  return (
    <BrowserRouter>
      <ImageViewerProvider>
        <AnimationViewerProvider>

        <div className="App">
          <ImageViewer/>
          <AnimDetailsView/>
          
          <div>
            <Header/>
            <Navbar/>
            
            <ScrollToTop>
              <Routes>
                  <Route path="/illusztracio" element={<Illusztracio/>}/>
                  <Route path="/animacio" element={<Animacio/>}/>
                  <Route path="/karakter-design" element={<KarakterDesign/>}/>
                  <Route path="/egyeb-grafikak" element={<OtherGraphics/>}/>
                  <Route path="/szolgaltatasok" element={<Szolgaltatasok/>}/>
                  <Route path="/rolam" element={<About/>}/>
                  <Route path="/kapcsolat" element={<Contact/>}/>
                  <Route path="/" element={<Home/>}/>
                
              </Routes>
            </ScrollToTop>
          </div>

          <Footer/>
        </div>
        </AnimationViewerProvider>
      </ImageViewerProvider>
    </BrowserRouter>
  );
}

export default App;
