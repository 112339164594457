import React from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import { AnimationViewerContext } from '../contexts/AnimationViewerContext'
import { useLocation, useNavigate } from 'react-router-dom'
import {motion} from "framer-motion"
import { useEffect, useRef } from 'react'


export default function AnimDetailsView() {
    const {anim_data, set_details_url} = useContext(AnimationViewerContext)
    const [current_clip, set_current_clip] = useState(null)
    const location = useLocation()
    const navigate = useNavigate()
    const videoRef = useRef();
    
    document.body.style.overflowY = "hidden";

    const close_clicked = () =>{
        navigate({search: ""})
        set_details_url(null)
    }

    useEffect(() => {
        if(anim_data){
            if(current_clip.endsWith(".mp4")){
                videoRef.current.load();
            }

            navigate({search: `?imageid=${anim_data.id}`})
        }
    }, [current_clip])

    useEffect(() => {
        if(anim_data){
            if(anim_data.video){
                set_current_clip(anim_data.video)
            }else{
                set_current_clip(anim_data.playlist[0])
            }
        }
    }, [anim_data])


    if(anim_data === null){
        document.body.style.overflowY = "auto";
        return
    }

    return (
    <div className='image-viewer-container' onClick={close_clicked}>
        <div className='anim-details-container' onClick={close_clicked}>
            <motion.i className="fa fa-window-close" aria-hidden="true" onClick={close_clicked} whileHover={{scale: 1.3}}/>
            <div className='player' onClick={e => e.stopPropagation()}>
                {
                    current_clip&&
                    current_clip.endsWith(".mp4")?
                    <video controls autoPlay loop muted playsInline ref={videoRef}>
                        <source src={current_clip} type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                    :
                    <img src={current_clip} alt="" onClick={e => e.stopPropagation()}/>
                }
            </div>

            <div className='description'>
                    <h1>{anim_data.title}</h1>
                    <p>{anim_data.subtitle}</p>
                    <div dangerouslySetInnerHTML={{ __html: anim_data.description}} onClick={e => e.stopPropagation()}/>
            </div>
            
            {
            anim_data.playlist.length > 1 &&
            <div className='anim-image-strip'>
                {
                    anim_data.playlist.map(clip => 
                        <div key={clip} onClick={e => e.stopPropagation()}>
                            {
                                clip.endsWith(".mp4")?
                                    <video autoPlay loop muted playsInline onClick={e => set_current_clip(clip)} className={`${location.pathname !== "/animacio"? "portrait ":""}${current_clip === clip && "current-clip"}`}>
                                        <source src={clip} type="video/mp4"/>
                                        Your browser does not support the video tag.
                                    </video>
                                : // if clip is an image
                                    clip === anim_data.cover_image && anim_data.video? // this is the cover image for the video
                                        <img src={clip} alt="" onClick={e => set_current_clip(anim_data.video)} className={`${location.pathname !== "/animacio"? "portrait ":""}${current_clip === anim_data.video && "current-clip"}`}/>
                                    :
                                        <img src={clip} alt="" onClick={e => set_current_clip(clip)} className={`${location.pathname !== "/animacio"? "portrait ":""}${current_clip === clip && "current-clip"}`}/>
                            }
                        </div>
                    )
                }
            </div>
            }

        </div>
    </div>
    )
}
