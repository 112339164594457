import React, { useContext } from 'react';
import {motion} from "framer-motion"
import Masonry from 'react-masonry-css';
import { ImageViewerContext } from '../contexts/ImageViewerContext';

function Media({image_data}) {
  return(
    image_data.type === "image"? 
    <motion.img src={image_data.media} alt="" whileHover={{scale: 1.1}} draggable="false"/>
    :
    <motion.video autoPlay loop muted playsInline whileHover={{scale: 1.1}}>
      <source src={image_data.media} type="video/mp4"/>
      Your browser does not support the video tag.
    </motion.video>
  )
}

function Home() {
  const {set_image_by_id, image_list} = useContext(ImageViewerContext)

  const variants = {
    hidden: { opacity:0 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.05,
            },
        },
    }

  const item = {
      hidden:{
          opacity: 0,
          y: 50
      },
      show:{
          opacity: 1,
          y: 0,
          transition:{
              duration: 0.3
          }
      }
  }

  const breakpoints = {
    default: 3,
    700: 2,
    400: 1
  }

  return (
    <motion.div  
      className='home-gallery content-container'
      variants={variants}
      initial="hidden"
      animate="show"
    >
      <Masonry
        breakpointCols={breakpoints}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >

        {
          image_list.map(image_data => 
            <motion.div key={image_data.id} variants={item} className='image-box' onClick={()=> set_image_by_id(image_data.id)}>
              <Media image_data={image_data}/>
            </motion.div>
          )
        }

      </Masonry>
    </motion.div>
  );
}

export default Home;