import React, {useContext} from "react";
import { AnimationViewerContext } from "../../contexts/AnimationViewerContext";
import {motion} from "framer-motion"
import MoreImages from "../../images/images-solid.svg"


export default function Card({anim_data, api_path}){
    const {set_details_url} = useContext(AnimationViewerContext)
  
    return <div className='animation-card'>
      {
        anim_data.cover_image?
        <div onClick={e => set_details_url(`${api_path}/${anim_data.id}`)}>
          <i className="fa fa-film video-play-icon" aria-hidden="true"/>
          <motion.img src={anim_data.cover_image} alt="" whileHover={{scale: 1.1}} draggable="false"/>
        </div>
        :
        anim_data.video?
        <motion.video autoPlay loop muted playsInline onClick={e => set_details_url(`${api_path}/${anim_data.id}`)} whileHover={{scale: 1.1}}>
          <source src={anim_data.video} type="video/mp4"/>
          Your browser does not support the video tag.
        </motion.video>
        :
        <motion.img src={anim_data.playlist[0]} alt="" whileHover={{scale: 1.1}} draggable="false" onClick={e => set_details_url(`${api_path}/${anim_data.id}`)}/>
      }
      {
        !anim_data.cover_image && anim_data.playlist.length > 1&& <div className='more-image'><img src={MoreImages} alt="" /></div>
      }
      <div className='black-bg'/>
      <div className='title'>
        {anim_data.title}
      </div>
  
      </div>
  }