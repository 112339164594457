import React, { useState } from 'react'

export default function SocialIcon({normal, hovered, link}) {
    const [icon, set_icon] = useState(normal)

  return (
    <a 
        href={link} 
        target="_blank" 
        rel="noopener noreferrer" 
        onMouseOver={(e) => set_icon(hovered)} 
        onMouseLeave={(e) => set_icon(normal)}>
        <img src={icon} alt="icon" draggable="false" className='social-icon'/>
    </a>
  )
}
