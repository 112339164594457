import axios from "axios";
import React, {createContext, useEffect, useState} from "react";

const API_URL = process.env.REACT_APP_API_URL

export const ImageViewerContext = createContext(true);
 
export const ImageViewerProvider = (props) => {
    const [image_data, set_image_data] = useState(null);
    const [image_index, set_image_index] = useState(0);
    const [image_list, set_image_list] = useState([])

    const fetch_images = () => {
        axios({
            method: "get",
            url: `${API_URL}/api/frontpage/`
        }).then(result => set_image_list(result.data))
    }

    const set_next_image = () => {
        let _image_index = image_index
        _image_index ++

        if(_image_index >= image_list.length){
            _image_index = image_list.length - 1
        }

        set_image_index(_image_index)
        set_image_data(image_list[_image_index])
    }

    const set_previous_image = () => {
        let _image_index = image_index
        _image_index --
        if(_image_index < 0){
            _image_index = 0
        }

        set_image_index(_image_index)
        set_image_data(image_list[_image_index])
    }

    const set_image_by_id = (id) => {
        for(let i=0; i<image_list.length; i++){
            let _image_data = image_list[i]
            if(_image_data.id === id){
                set_image_index(i)
                set_image_data(_image_data)
            }
        }
    }

    useEffect(() => {
        fetch_images()
    }, [])
 
    return (
        <ImageViewerContext.Provider
            value={{
                image_data: image_data,
                image_list: image_list,
                image_index: image_index,
                set_image_data: set_image_data,
                set_next_image: set_next_image,
                set_previous_image: set_previous_image,
                set_image_by_id: set_image_by_id
            }}>
 
            {props.children}
 
        </ImageViewerContext.Provider>
    )
};