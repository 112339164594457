import React from 'react';
import AboutImage from "../images/about_image.png"
import {motion} from "framer-motion"
import ExternalLink from "./micro/ExternalLink"

// get app logos
import AdobeLogo from "../images/logo_photoshop.png"
import ProcreateLogo from "../images/logo_procreate.png"
import CalipegLogo from "../images/logo_callipeg.png"
import BlenderLogo from "../images/logo_blender.png"
import AspriteLogo from "../images/logo_aseprite.png"
import PyxelEditLogo from "../images/logo_pyxeledit.png"
import AffinityLogo from "../images/logo_affinity.png"
import VegasLogo from "../images/logo_vegas-pro.png"
import IndesignLogo from "../images/logo_indesign.png"

function AppLogo({image, name}){
  return <div className='app-logo-container'>
    <img src={image} alt="" />
    <p>{name}</p>
  </div>
}

function About() {
  const variants = {
    hidden: { opacity:0 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.05,
            },
        },
    }

  const item = {
      hidden:{
          opacity: 0,
          x: 50
      },
      show:{
          opacity: 1,
          x: 0,
          transition:{
              duration: 0.3
          }
      }
  }

  return (
    <div className='about-container content-container'>
      <motion.img src={AboutImage} alt="" initial={{opacity: 0}} animate={{opacity: 1}} draggable="false"/>
      
      <motion.div
            variants={variants}
            initial="hidden"
            animate="show"
      >
        <p className='about-text'> Szép napot! A nevem Rödönyi Csilla. Illusztrációval, animációval és karakter tervezéssel foglalkozom. Többnyire stilizált műfajban dolgozom és pixelartokat is készítek. Animátorként első sorban a 2D animáció a fő profilom, de a 3D sem ismeretlen a számomra.</p>

        <motion.div variants={item}>
          <h3>TANULMÁNYOK</h3>
          <ul>
            <li>2014-2016: OKLEVELES ANIMÁCIÓS TERVEZŐMŰVÉSZ (MA) <br />Budapesti Metropolitan Egyetem</li>
            <li>2011-2014: ANIMÁCIÓS TERVEZŐ (BA) <br />Budapesti Metropolitan Egyetem</li>
            <li>2008-2010: MŰVÉSZETI GRAFIKUS <br />Budai Rajziskola</li>
            <li>2006-2008: ANIMÁTOR KURZUS RÓFUSZ FERENCCEL <br />Budai Rajziskola</li>
          </ul>
        </motion.div>

        <motion.div variants={item}>
          <h3>MUNKA TAPASZTALAT</h3>
          <ul>
            <li>2023-2024: 3D Animátor / freelancer (Krea-TV)<br/>Karakteranimációkat készítettem a "*Budapest Spaceport*" című animációs sorozathoz</li>
            <li>2022-2023: 2D Animátor / freelancer (Krea-TV)<br/>Én készítettem <ExternalLink link="https://info.bmc.hu/hirek/mma-portrek-undisturbed-hommage-a-uzs" text='"M Tóth Éva - UNDISTURBED / Hommage à ÚZS"'/> című dokumentimfilmjének animációs betéteit</li>
            <li>2022: Kártyapakli illusztrátor (Electricks Kft.)<br/>Joker és bűvészkártyákat, valamint kártyadobozt terveztem, melyek <ExternalLink link="https://electricks.info/product/luna-marked-flash-cards/" text="ezen az oldalon"/> megtalálhatóak</li>
            <li>2020-2022: Szabadúszó animátor és grafikus</li>
            <li>2019: 3D Animátor (NeoCore Games) <br />3D Animátorként dolgoztam a <ExternalLink link="https://neocoregames.com/en/store/warhammer-40000-inquisitor-prophecy" text='"Warhammer 40.000: Inquisitor Prophecy"'/> játékban.</li>
            <li>2017- 2018: 3D Animátor (Alphaville Virtual Studios)</li>
            <li>2016-2017: 2D Animátor (KEDD Animation Studio) <br />2D animátorként dolgoztam az <ExternalLink link="https://www.keddstudio.com/index.php/kuflik/" text='"Egy kupac kufli"'/> című animációs sorozatban</li>
            <li>2015-2016: Illusztrátor (Tessloff-Babilon Kiadó) <br />Mán-Várhegyi Réka - <ExternalLink link="https://bookline.hu/product/home.action?_v=Man_Varhegyi_Reka_Kokusz_Franci_a_fodr&type=22&id=277747" text='"Kókusz Franci, a fodrász titkosügynök"'/> című mesekönyvét illusztráltam</li>
            <li>2015: Animációs tervező / Animátor (Libri Kiadó) <br />Én készítettem Bud Spencer - <ExternalLink link="https://www.youtube.com/watch?v=lAckko5sLTA" text='"80 év alatt a Föld körül"'/> című önéletrajzi írásának hivatalos könyvtrailerét.</li>
            <li>2012-2014: Animációs tervező / Karakter tervező / Animátor (Colorspectrum Kft.) <br />A <ExternalLink link="https://mobile.twitter.com/postlines" text='"PostLines"'/> című Iphone készülékekhez készült játék karakterveit, látványterveit és átkötő animációit készítettem.</li>
          </ul>
        </motion.div>

        <motion.div variants={item}>
          <h3>DÍJAK & ELISMERÉSEK</h3>
          <ul>
            <li>2016. Szeptember: <ExternalLink link="https://metropolitan.hu/metu-art-diploma-dij-2016" text="METU Art Diploma Díj"/> - Budapesti Metropolitan Egyetem <br />A Budapesti Metropolitan Egyetem 2016-ban a művészeti mesterképzéseken diplomázó, legjobb eredményt elért hallgatók tanulmányi, szakmai, közösségi munkája elismerésérre alapított  ART Diploma díja.</li>
            <li>2005 és 2006: <ExternalLink link="https://www.origo.hu/teve/20051102megvannak.html" text="Cartoon Network - Animáció Generáció"/> - 1. helyezett</li>
          </ul>
        </motion.div>
        <motion.div variants={item}>
          <h3>ANIMÁCIÓS RÖVIDFILMJEIM A FILM FESZTIVÁLOKON</h3>
          <ul>
            <li><ExternalLink link='https://www.youtube.com/watch?v=2VoN8P8_Etg' text='GOMOLY (CRUSH)'/> / 2D rövidfilm / 2016</li>
            <ul>
              <li>- BALKANIMA EUROPEAN ANIMATED FILM FESTIVAL - Versenyben - 2017</li>
              <li>- SUPERTOON INTERNATIONAL ANIMATION FESTIVAL - Versenyben - 2017</li>
              <li>- CineMAiubit INTERNATIONAL STUDENT FILM FESTIVAL - Versenyben - 2017</li>
              <li>- FESTIVAL OF EUROPEAN STUDENT ANIMATION / FESA - Versenyben - 2017</li>
              <li>- ReANIMA INTERNATIONAL ANIMATION FILM & COMICS ART FESTIVAL - Versenyben - 2017</li>
              <li>- ZSIGMOND VILMOS INTERNATIONAL FILM FEST - Versenyben - 2018</li>
              <li>- BUDAPEST SHORT FILM FESTIVAL / BUSHO - Hungarorama Films Program - 2017</li>
              <li>- FALUDI FERENC ACADEMY INTERNATIONAL FILM FESTIVAL - A fesztivál programjában - 2017</li>
              <li>- PÁZMÁNY FILM FESTIVAL (Hungary, Budapest) - Versenyben - 2017</li>
              <li>- SLOW FILM FESTIVAL (Hungary, Eger) - Versenyben - 2017</li>
            </ul>
            <li><ExternalLink link='https://www.youtube.com/watch?v=jiMPkJRApik&ab_channel=KAFFHungary' text='HIVATALOS KAFF FESZTIVÁL SPOTFILM'/> / 2015 <br />A rövidfilmem a Kecskeméti Animációs Filmfesztivál egyik hivatalos spot filmje lett.</li>
            <li><ExternalLink link='https://www.youtube.com/watch?v=lAckko5sLTA&ab_channel=Nyugatiter' text='BUD SPENCER - 80 ÉV ALATT A FÖLD KÖRÜL'/> / Könyvtrailer / 2015</li>
            <ul><li>- KECSKEMÉTI ANIMÁCIÓS FILMFESZTIVÁL - Versenyben - 2015</li></ul>
            <li><ExternalLink link="http://2015.kaff.hu/content/show_datasheet_by_entry_id/918/0/134/competetion" text="A NÉMA HEGEDŰ"/> / 2D rövidfilm trailer / 2014</li>
            <ul><li>- KECSKEMÉTI ANIMÁCIÓS FILMFESZTIVÁL - Versenyben - 2015</li></ul>
            <li><ExternalLink link="http://2013.kaff.hu/content/show_datasheet_by_entry_id/124/0/24/competetion" text="GRAFFITI"/> / 2D rövidfilm / 2013</li>
            <ul>
              <li>- HIROSHIMA INTERNATIONAL FILM FESTIVAL - A fesztivál programjában - 2014</li>
              <li>- KECSKEMÉTI ANIMÁCIÓS FILMFESZTIVÁL - Versenyben - 2013</li>
              <li>- FFEST INTERNATIONAL FILM FESTIVAL - Versenyben - 2013</li>
            </ul>
            <li><ExternalLink link='https://www.cheellart.com/reading-is-good-stop-motion-short' text='OLVASNI JÓ!'/> / stop-motion rövidfilm / 2013</li>
            <ul>
              <li>- PRIMANIMA INTERNATIONAL FILM FESTIVAL - Versenyben - 2013</li>
              <li>- FFEST INTERNATIONAL FILM FESTIVAL - Versenyben - 2013</li>
            </ul>
            <li><ExternalLink link='https://www.cheellart.com/day-night-animated-short' text='NAPÉJ'/> / 2D rövidfilm / 2013</li>
            <ul>
              <li>- PRIMANIMA INTERNATIONAL FILM FESTIVAL - Versenyben - 2013</li>
              <li>- FFEST INTERNATIONAL FILM FESTIVAL - Versenyben - 2013</li>
            </ul>
          </ul>
          
          <motion.div variants={item}>
            <h3>NYELVEK</h3>
            <ul>
              <li>Angol</li>
              <li>Német </li>
            </ul>
          </motion.div>

          <motion.div variants={item}>
            <h3>SZOFTVERISMERET</h3>
            <div className='app-list-container'>
              <AppLogo image={AdobeLogo} name="Adobe Photoshop"/>
              <AppLogo image={ProcreateLogo} name="Procreate"/>
              <AppLogo image={CalipegLogo} name="Callipeg"/>
              <AppLogo image={AspriteLogo} name="Aseprite"/>
              <AppLogo image={PyxelEditLogo} name="PyxelEdit"/>
              <AppLogo image={AffinityLogo} name="Affinity Designer"/>
              <AppLogo image={BlenderLogo} name="Blender"/>
              <AppLogo image={VegasLogo} name="Vegas Pro"/>
              <AppLogo image={IndesignLogo} name="Adobe InDesign"/>
            </div>            
          </motion.div>
        </motion.div>
      </motion.div>
    </div>
  )
}

export default About