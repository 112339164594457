import React from 'react'
import { useState } from 'react'
import { useEffect, useRef } from 'react'
import {Link} from "react-router-dom"
import axios from 'axios'
import {motion} from "framer-motion"


const API_URL = process.env.REACT_APP_API_URL

function Card({slideshow, title, description, button_title, to, right}){
    const [clip, set_clip] = useState(slideshow[0])
    const [clip_index, set_clip_index] = useState(0)
    const [my_timer, set_my_timer] = useState(null)
    const videoRef = useRef();
    let index = 0

    const change_index = () => {
        if(index >= slideshow.length){
            index = 0
        }
        set_clip_index(index)
        index ++
    }

    const start_slideshow = () => {
        index ++
        set_my_timer(setInterval(change_index, 1500))
    }

    const stop_slideshow = () => {
        index = 0
        clearInterval(my_timer)
        set_my_timer(null)
        set_clip_index(0)
    }

    useEffect(() => {
        let _new_clip = slideshow[clip_index]
        clip.endsWith(".mp4")&& videoRef.current.load(_new_clip)
        set_clip(_new_clip)
    }, [clip_index])

    return(
        <motion.div className='card-container' whileHover={{scale: 1.03}} onMouseEnter={start_slideshow} onMouseLeave={stop_slideshow}>
            {
                clip.endsWith(".mp4")?
                <video autoPlay loop muted playsInline className={!right? "image-right": ""} ref={videoRef}>
                    <source src={clip} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
                :
                <motion.img src={clip} alt="" className={!right? "image-right": ""} initial={{opacity: 0}} animate={{opacity: 1}}/>
            }

            <div className={`content ${!right? "right":""}`}>
                <h1>{title}</h1>
                <p>{description}</p>
                <Link to={to}><button>{button_title}</button></Link>                
            </div>
        </motion.div>
    )
}

export default function Szolgaltatasok() {
    const [content_data, set_content_data] = useState([])
    
    const variants = {
        hidden: { opacity:0 },
        show: {opacity: 1}
    }

    useEffect(() =>{
        axios({
            method: "get",
            url: `${API_URL}/api/services/`
        }).then(res => set_content_data(res.data))
    }, [])

    return (
        <motion.div 
        className='content-container services'
        variants={variants}
        initial="hidden"
        animate="show"
        >
            {
                content_data.map((data, index) => 
                    <Card 
                        key={data.id}
                        slideshow={data.slideshow} 
                        title={data.title} 
                        description={data.description} 
                        button_title={data.button_title} 
                        to={data.to}
                        right={index % 2 == 0}
                    />
                )
            }
        </motion.div>
    )
}
