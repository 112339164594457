import React, { useState, useEffect } from 'react';
import {motion} from "framer-motion"
import Masonry from 'react-masonry-css';
import axios from "axios"
import { useContext } from 'react';
import { AnimationViewerContext } from '../contexts/AnimationViewerContext';
import Card from './micro/Card';

const API_URL = process.env.REACT_APP_API_URL

function Illusztracio() {
  const [anim_list, set_anim_list] = useState([])

  const variants = {
    hidden: { opacity:0 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.05,
            },
        },
  }

  const item = {
      hidden:{
          opacity: 0,
          y: 50
      },
      show:{
          opacity: 1,
          y: 0,
          transition:{
              duration: 0.3
          }
      }
  }

  const breakpoints = {
    default: 3,
    700: 2,
    400: 1
  }

  const fetch_char_designs = () => {
    axios({
      method: "get",
      url: `${API_URL}/api/illustrations/`
    }).then(res => set_anim_list(res.data))
  }

  useEffect(() => {
    fetch_char_designs()
  }, [])

  return (
    <motion.div className='home-gallery content-container' initial={{opacity:0}} animate={{opacity:1}}>
      <Masonry
      breakpointCols={breakpoints}
      className="my-masonry-grid"
      columnClassName="my-masonry-grid_column"
      >
        {
          anim_list.map(anim_data => 
            <motion.div key={anim_data.id} variants={item} className='image-box'>
              <Card anim_data={anim_data} api_path="illustrations"/>
            </motion.div>
          )
        }
      </Masonry>

    </motion.div >
  )
}

export default Illusztracio