import React from "react";
import { motion } from "framer-motion"
import SocialIcon from "./micro/SocialIcon";

// import all icons
import Facebook from "../images/facebook.png"
import FacebookHovered from "../images/facebook_hovered.png"
import Linkedin from "../images/linkedin.png"
import LinkedinHovered from "../images/linkedin_hovered.png"
import Twitter from "../images/twitter.png"
import TwitterHovered from "../images/twitter_hovered.png"
import Artstation from "../images/artstation.png"
import ArtstationHovered from "../images/artstation_hovered.png"
import Cheelart from "../images/cheellart.png"
import CheelartHovered from "../images/cheellart_hovered.png"
import Youtube from "../images/youtube.png"
import YoutubeHovered from "../images/youtube_hovered.png"

function SocialLinks(){
    const variants = {
        hidden: { opacity:0 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1,
            },
        },
    }

    const item = {
        hidden:{
            opacity: 0,
            x: 50
        },
        show:{
            opacity: 1,
            x: 0,
            transition:{
                duration: 0.4
            }
        }
    }

    return(
        <motion.div 
        className='social-icons-container'
        variants={variants}
        initial="hidden"
        animate="show"
        >
            <motion.div variants={item}><SocialIcon normal={Youtube} hovered={YoutubeHovered} link="https://www.youtube.com/user/rCheella"/></motion.div> 
            <motion.div variants={item}><SocialIcon normal={Twitter} hovered={TwitterHovered} link="https://twitter.com/CsillaRodonyi"/></motion.div> 
            <motion.div variants={item}><SocialIcon normal={Facebook} hovered={FacebookHovered} link="https://www.facebook.com/profile.php?id=100085372675954"/></motion.div> 
            <motion.div variants={item}><SocialIcon normal={Artstation} hovered={ArtstationHovered} link="https://www.artstation.com/csillarodonyi"/></motion.div> 
            <motion.div variants={item}><SocialIcon normal={Linkedin} hovered={LinkedinHovered} link="https://www.linkedin.com/in/csilla-rodonyi/"/></motion.div> 
            <motion.div variants={item}><SocialIcon normal={Cheelart} hovered={CheelartHovered} link="https://www.cheellart.com"/></motion.div> 

        </motion.div>
    )
}

export default SocialLinks