import React from 'react';
import SocialLinks from './SocialLinks';
import {motion} from "framer-motion"
import { useLocation } from 'react-router-dom'

function Footer() {
  const location = useLocation()

  return (
    <div className='footer'>
      {
        location.pathname !== "/kapcsolat"&& <motion.div initial={{y: -10}}  animate={{y:0}} className='selectable-text'>cheellart@gmail.com</motion.div>
      }
        <SocialLinks/>
        <motion.small initial={{y: 10}}  animate={{y:0}}>© by Csilla Rödönyi. All rights reserved. {new Date().getFullYear()}</motion.small>
    </div>
  );
}

export default Footer;